document.addEventListener('DOMContentLoaded', function () {
  var locationSingleTop = document.querySelector('.location-single-top');
  var cityTemplate = document.querySelector('.city-template');

  if (locationSingleTop && cityTemplate) {
    cityTemplate.parentNode.insertBefore(locationSingleTop, cityTemplate);
  }
});


const hiringCta = document.querySelector('.hiring-cta');

const clonedHiringCta = hiringCta.cloneNode(true);

const mainCol = document.querySelector('.main-col');

mainCol.insertAdjacentElement('afterbegin', clonedHiringCta);
